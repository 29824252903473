import axios from 'axios';
import {getToken} from "@/utils/auth";

let instance = axios.create();

instance.interceptors.request.use((config) => {
    // 使用 js-cookie 获取 token
    let token = getToken();
    if (!token) {
       // router.push('/index');
        return;
    }
    config.headers.Authorization = token;
    return config;
})

const TokenAxios = {
    get(url, params) {
        return instance({
            method: 'GET',
            url: url,
            params: params
        });
    },
    post(url, data) {
        return instance({
            method: 'POST',
            url: url,
           // data: qs.stringify(params)
            data: data
        });
    },
    update(url, params) {
        return instance({
            method: 'PUT',
            url: url,
            data: params
        });
    },
    delete(url) {
        return instance({
            method: 'DELETE',
            url: url ,
        });
    }
}

export default TokenAxios;
