<template>
  <div class="loginAndReg">
    <div v-if="showPopup" class="popup-container">
      <div class="popup-header">
        <img class="reg-logo" src="/imgs/components/loginAndReg/logo.png" alt="">
        <span class="close-btn" @click="closePopup">&times;</span>
      </div>
      <div class="switch-btns">
        <button @click="switchToLogin">登录</button>
        <button @click="switchToRegister">注册</button>
      </div>

      <div v-if="showLogin">
        <el-form ref="loginForm" :model="loginForm" :rules="loginRules" :inline-message="true">
          <el-form-item prop="usernameORPhoneNumber">
            <div class="form-group">

              <i class="el-icon-my-login-phone"></i>
              <input class="text-input" type="text" placeholder="请输入用户名/手机号"
                     v-model="loginForm.usernameORPhoneNumber"
              >

            </div>
          </el-form-item>
          <el-form-item prop="password">
            <div class="form-group">
              <i class="el-icon-my-password"></i>
              <input class="text-input" type="password" placeholder="请输入密码"
                     v-model="loginForm.password"
              >
            </div>
          </el-form-item>
          <el-form-item prop="code" v-if="captchaEnabled">
            <div class="form-group" style="display: flex;justify-content: center; align-items: center;">

              <i class="el-icon-my-code"></i>
              <input style="width: 45%;height: 38px;" type="text" placeholder="验证码"
                     v-model="loginForm.code"
                     @keyup.enter="handleLogin">
              <img :src="codeUrl" @click="getCode" class="login-code-img"/>

            </div>
          </el-form-item>
          <div class="privacy-policy">
            <input type="checkbox" id="privacyCheckbox" v-model="privacyAgreement">
            <label for="privacyCheckbox">
              我已阅读并同意
              <a href="/UserPrivacyAgreement" target="_blank" style="text-decoration: none;">《用户隐私协议》</a>
            </label>
            <h6>版权所有：太原凡恩教育科技有限公司</h6>
          </div>
          <el-form-item>
            <div class="form-group">
              <button @click.prevent="handleLogin()">登录</button>
            </div>
          </el-form-item>
        </el-form>
      </div>

      <div v-if="!showLogin">
        <el-form ref="registerForm" :model="registerForm" :rules="registerRules"
                 :inline-message="true">

          <el-form-item prop="regPhonenumber">
            <div class="form-group">
              <i class="el-icon-my-login-phone"></i>
              <input class="text-input" type="text" placeholder="手机号码" v-model="registerForm.regPhonenumber"
                     @keyup.enter="register">
            </div>
          </el-form-item>
          <el-form-item prop="regPhoneCode">
            <div class="form-group" style="display: flex;justify-content: center; align-items: center;">
              <i class="el-icon-my-code"></i>
              <input style="width: 49%;height: 30px ;" type="text" placeholder="验证码"
                     v-model="registerForm.regPhoneCode">

              <span class="text-input-code">
                <button style="display: flex; width: 100%;height: 30px; align-items: center;"
                        @click.prevent="getRegPhoneCode">
                    <span v-if="countdown === 0">获取验证码</span>
                    <span v-else>{{ countdown }}秒后重新获取</span>
                </button>
              </span>

            </div>
          </el-form-item>
          <el-form-item prop="regPassword">
            <div class="form-group">
              <i class="el-icon-my-password"></i>
              <input class="text-input" type="password" placeholder="设置密码" v-model="registerForm.regPassword">
            </div>
          </el-form-item>
          <el-form-item prop="confirmPassword">
            <div class="form-group">
              <i class="el-icon-my-password"></i>
              <input class="text-input" type="password" placeholder="确认密码" v-model="registerForm.confirmPassword">
            </div>
          </el-form-item>
          <el-form-item prop="code" v-if="captchaEnabled">
            <div class="form-group" style="display: flex;justify-content: center; align-items: center;">

              <i class="el-icon-my-code"></i>
              <input style="width: 45%;height: 38px;" type="text" placeholder="验证码"
                     v-model="registerForm.regCode"
              >
              <img :src="codeUrl" @click.prevent="getCode" class="login-code-img"/>

            </div>
          </el-form-item>
          <div class="form-group">
            <div class="privacy-policy">
              <input style="width: 5%" type="checkbox" id="privacyCheckbox" v-model="privacyAgreement">
              <label for="privacyCheckbox">
                我已阅读并同意
                <a href="/UserPrivacyAgreement" target="_blank" style="text-decoration: none;">《用户隐私协议》</a>
              </label>
              <h6>版权所有：太原凡恩教育科技有限公司</h6>
            </div>
            <button @click.prevent="register"
            >注册
            </button>
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import Cookies from "js-cookie";
import {decrypt, encrypt} from "@/utils/jsencrypt";
import {getToken, setToken} from "@/utils/auth";
import user from "@/store/modules/user";
import SimpleAxios from "@/http/SimpleAxios";
import TokenAxios from "@/http/TokenAxios";


export default {
  data() {
    const equalToPassword = (rule, value, callback) => {
      if (this.registerForm.password !== value) {
        callback(new Error("两次输入的密码不一致"));
      } else {
        callback();
      }
    };
    return {
      countdown: 0, // 倒计时时间（秒）
      timer: null, // 存储倒计时的计时器
      showPopup: false,
      showLogin: true,
      loginForm: {
        usernameORPhoneNumber: "",
        password: "",
        rememberMe: false,
        code: "",
        uuid: ""
      },
      registerForm: {
        regPhonenumber: "",
        regPhoneCode: "",
        regPassword: "",
        confirmPassword: "",
        regCode: "",
        regUuid: ""
      },
      loginRules: {
        usernameORPhoneNumber: [
          {required: true, trigger: "blur", message: "请输入您的账号"}
        ],
        password: [
          {required: true, trigger: "blur", message: "请输入您的密码"}
        ],
        code: [{required: true, trigger: "change", message: "请输入验证码"}]
      },
      registerRules: {
        regPhonenumber: [
          {required: true, trigger: "blur", message: "请输入您的手机号"},
          {min: 11, max: 11, message: '请检查手机号码是否正确', trigger: 'blur'}
        ],
        regPhoneCode: [
          {required: true, trigger: "blur", message: "请输入手机验证码"},
          {min: 5, max: 5, message: '验证码为5位数字', trigger: 'blur'}
        ],
        regPassword: [
          {required: true, trigger: "blur", message: "请输入您的密码"},
          {min: 8, max: 20, message: "用户密码长度必须介于 8 和 20 之间", trigger: "blur"},
          {pattern: /^[^<>"'|\\]+$/, message: "不能包含非法字符：< > \" ' \\\ |", trigger: "blur"}
        ],
        confirmPassword: [
          {required: true, trigger: "blur", message: "请再次输入您的密码"},
          {required: true, validator: equalToPassword, trigger: "blur"}
        ],
        regCode: [{required: true, trigger: "change", message: "请输入验证码"}]
      },
      loading: false,
      // 验证码开关
      captchaEnabled: true,
      privacyAgreement: false,
      codeUrl: "",
      registerPhoneNumber: '',
      verificationCode: '',
      registerPassword: '',

    }
  },
  created() {
    this.getCode();
    this.getCookie();
  },
  methods: {
    closePopup(e) {
      this.showPopup = false;
      this.$emit('popupClosed');
    },

    getCookie() {
      const usernameORPhoneNumber = Cookies.get("usernameORPhoneNumber");
      const password = Cookies.get("password");
      const rememberMe = Cookies.get('rememberMe')
      this.loginForm = {
        usernameORPhoneNumber: usernameORPhoneNumber === undefined ? this.loginForm.usernameORPhoneNumber : usernameORPhoneNumber,
        password: password === undefined ? this.loginForm.password : decrypt(password),
        rememberMe: rememberMe === undefined ? false : Boolean(rememberMe)
      };
    },

    handleLogin() {
      if (!this.privacyAgreement) {
        this.$message.error('请阅读并同意用户隐私协议后继续!');
        // this.showPopup = true;
        return; // 终止执行登录逻辑
      }
      let url = this.BaseUrl.LOGIN_AND_REGISTER + '/loginOffcialWebsite';
      this.$refs.loginForm.validate(valid => {
        if (valid) {
          this.loading = true;
          //  console.log("用户名或手机号:", this.loginForm.usernameORPhoneNumber); // 打印输出
          if (this.loginForm.rememberMe) {
            Cookies.set("usernameORPhoneNumber", this.loginForm.usernameORPhoneNumber, {expires: 30});
            Cookies.set("password", encrypt(this.loginForm.password), {expires: 30});
            Cookies.set('rememberMe', this.loginForm.rememberMe, {expires: 30});
          } else {
            Cookies.remove("usernameORPhoneNumber");
            Cookies.remove("password");
            Cookies.remove('rememberMe');
          }
          this.axios.post(url,
              {
                usernameORPhoneNumber: this.loginForm.usernameORPhoneNumber,
                password: this.loginForm.password,
                code: this.loginForm.code,
                uuid: this.loginForm.uuid,
                rememberMe: this.loginForm.rememberMe
              }).then((res) => {
            if (res.data.code === 200) {
              this.$message.success("登录成功！");
              //关闭登录窗口
              this.closePopup();
              //console.log(user)
              //  localStorage.setItem("loginInfo", JSON.stringify(res.data));
              //     location.reload();
              setToken(res.data.token)
              //    console.log(res);
              // user.state.token = getToken()
              //  console.log(user)
              this.getUerInfo()
            } else {
              this.$message.error(res.data.msg);
              if (res.data.code === 500) {
                this.getCode();
              }
            }
          }).catch(() => {
            this.loading = false;
            if (this.captchaEnabled) {
              this.getCode();
            }
          });
        }
      });
    },
    getUerInfo() {
      let url = this.BaseUrl.LOGIN_AND_REGISTER + '/getInfo';
      TokenAxios.get(url).then((res) => {
        if (res.data.code === 200) {
          // console.log(res.data.user)
          //  user=res.data.user
          const userInfo = {
            userId: res.data.user.userId,
            avatar: res.data.user.avatar,
            userName: res.data.user.userName,
            nickName: res.data.user.nickName,
            phoneNumber: res.data.user.phonenumber
          }
          if (!res.data.user.avatar) {
            userInfo.avatar = "/defaultAvatar.png"
          } else {
            userInfo.avatar = res.data.user.avatar
          }
          if (res.data.roles && res.data.roles.length > 0) { // 验证返回的roles是否是一个非空数组
            user.state.roles = res.data.user.roles
            user.state.permissions = res.data.user.roles.permissions
          } else {
            user.roles = ['ROLE_DEFAULT']
          }
          /*   user.state.id = res.data.user.userId
             user.state.username = res.data.user.userName
             user.state.nickname = res.data.user.nickName
             user.state.phoneNumber = res.data.user.phonenumber*/

          //   console.log('userInfo:', userInfo);
          localStorage.setItem('loginInfo', JSON.stringify(userInfo))
          //   console.log(JSON.parse(localStorage.loginInfo))
          location.reload();
          //   console.log(user)

        }
      }).catch((error) => {
        return Promise.reject(error)
      })
    },
    getCode() {
      let url = this.BaseUrl.LOGIN_AND_REGISTER + '/captchaImage'
      SimpleAxios.get(url).then((res) => {
        if (res.data.code === 200) {
          this.captchaEnabled = res.data.captchaEnabled === undefined ? true : res.data.captchaEnabled;
          if (this.captchaEnabled) {
            this.codeUrl = "data:image/gif;base64," + res.data.img;
            this.loginForm.uuid = res.data.uuid;
            this.registerForm.regUuid = res.data.uuid;
          }
        } else {
          if (res.data.msg === null || res.data.msg === "") {
            this.$message.error("服务器繁忙请稍后再试！");
          }
          this.$message.error(res.data.msg);
        }
      }).catch((error) => {
      });
    },


    register() {
      if (!this.privacyAgreement) {
        this.$message.error('请阅读并同意用户隐私协议后继续!');
        return; // 终止执行登录逻辑
      }
      let url = this.BaseUrl.REGISTER + '/register';
      let data = {
        phonenumber: this.registerForm.regPhonenumber,
        phoneCode: this.registerForm.regPhoneCode,
        password: this.registerForm.regPassword,
        confirmPassword: this.registerForm.confirmPassword,
        code: this.registerForm.regCode,
        uuid: this.registerForm.regUuid,
      }
      this.$refs.registerForm.validate(valid => {
            if (valid) {
              SimpleAxios.post(url, data)
                  .then((response) => {
                    console.log(response);
                    if (response.data.code === 200) {
                      this.$message.success("注册成功");
                      this.closePopup();
                      location.reload();
                    } else {
                      this.$message.error(response.data.msg);
                    }
                  }).catch((error) => {
                this.loading = false;
                if (this.captchaEnabled) {
                  this.getCode();
                }
              })
            }
          }
      )
    },
    getRegPhoneCode() {
      if (!this.privacyAgreement) {
        this.$message.error('请阅读并同意用户隐私协议后继续!');
        return; // 终止执行登录逻辑
      }
      if (this.registerForm.regPhonenumber === "") {
        this.$message.error("请输入手机号！");
      }
      this.$refs.registerForm.validateField('regPhonenumber', (valid) => {
        if (!valid) {
          let url = this.BaseUrl.PHONE_CODE + '/sendNote/userRegisterNote/' + this.registerForm.regPhonenumber;
          this.axios.get(url)
              .then((response) => {
                if (response.data.code === 200) {
                  this.startCountdown();
                  this.$message.success(response.data.msg);
                } else {
                  this.$message.error(response.data.msg);
                }
              })
              .catch((error) => {
                this.$message.error("服务器繁忙，请稍后再试！");
              });
        } else {
          console.log("请检查手机号码输入是否正确！");
        }
      });
    },
    startCountdown() {
      this.countdown = 60; // 设置倒计时时间为60秒
      this.timer = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown--;
        } else {
          clearInterval(this.timer); // 倒计时结束，清除计时器
        }
      }, 1000);
    },
    switchToLogin() {
      if (this.showLogin !== true) {
        this.$refs.registerForm.resetFields();
      }
      this.showLogin = true;
    },
    switchToRegister() {
      if (this.showLogin !== false) {
        this.$refs.loginForm.resetFields();
      }
      this.showLogin = false;
    },
  }
}

</script>

<style scoped>
.loginAndReg input {
  text-indent: 10px;
}

/* 弹出框容器的样式 */
.popup-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  padding: 0;
  border-radius: 30px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  max-width: 400px;
  width: 100%;
  z-index: 999;
}

/* 头部的样式 */
.popup-header {
  position: relative;
  background-image: url('/public/imgs/home/login/loginHeaderBackground.png'); /* 替换为您的图片路径 */
  background-size: cover;
  color: #fff;
  padding: 0;
  height: 120px;

  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.reg-logo {
  position: relative;
  left: -37%;
  top: 50%;
  width: auto;
  height: 30px;
}

/* 关闭按钮的样式 */
.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  color: #fff;
}

/* 登录/注册按钮的样式 */
.switch-btns {
  text-align: center;
  margin-bottom: 20px;
}

/* 表单的样式 */
.form-group {

}

.form-group input {
  width: 70%;
  padding: 0;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.privacy-policy {
  width: 100%;
  padding: 0;
  border-radius: 5px;
}

.form-group button {
  width: 70%;
  padding: 10px;
  border-radius: 5px;
  background-color: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;
}

.text-input {
  height: 30px;
  width: 70%;
}


.login-code-img {
  cursor: pointer;
  vertical-align: middle;
  height: 38px;
  border-radius: 8px;
  border-width: 0;
}

</style>
