import {getToken, removeToken} from '@/utils/auth'
import BaseUrl from "@/http/BaseUrl";
import TokenAxios from "@/http/TokenAxios";
import router from '@/router/index.js';



const user = {
  state: {
    token: getToken(),
    id: '',
    username: '',
    avatar: '',
    nickname: '',
    phoneNumber: '',
    roles: [],
    permissions: []
  },
}

export function userLogOut() {
  let url = BaseUrl.LOGIN_AND_REGISTER + '/logout';
  TokenAxios.post(url,null).then((response) => {
    console.log(response);
    if (response.data.code === 200) {
    //  this.$message.success("退出登录成功！");
      localStorage.removeItem('loginInfo');
      removeToken()
      location.reload();
    } else {
      this.$message.error(response.data.msg);
    }
  }).catch((error) => {
    console.error("退出登录错误:", error);
  });
}

export default user
