<template>
  <div class="submit-work-detail" v-if="SubmitWorkDetailShow">
    <div class="submit-work-detail-heard">
      <span>发布帖子</span>
      <el-popconfirm
          confirm-button-text='确定退出'
          cancel-button-text='不用了'
          icon="el-icon-info"
          icon-color="red"
          title="退出内容将被清空不会被保存，您确定要退出吗？"
          @confirm="closeSubmitWorkDetail"
      >
        <el-button class="submit-work-detail-close" icon="el-icon-my-close" slot="reference" circle></el-button>
      </el-popconfirm>
    </div>
    <hr>
    <div class="submit-work-detail-home">
      <el-form label-position="right" label-width="80px" :model="formLabelAlign">
        <el-form-item label="选择类别:">
          <el-select style="width: 100%" v-model="value" placeholder="请选择">
            <el-option
                v-for="item in categoryOptions"
                :key="item.keywords"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="标题:">
          <el-input v-model="formLabelAlign.title"></el-input>
        </el-form-item>
        <el-form-item label="内容:">
          <el-input
              type="textarea"
              :rows="8"
              maxlength="300"
              show-word-limit
              resize="none"
              v-model="formLabelAlign.detail"></el-input>
        </el-form-item>
        <el-form-item label="图片上传/视频:">
          <el-upload
              name="files"
              :auto-upload="false"
              :action="uploadUrl"
              :headers=headers
              :file-list="uploadFileList"
              ref="upload"
              :limit='9'
              list-type="picture-card"
              :on-change="handleFileChange"
              :on-error="handleError"
              :on-success="handleSuccess"
              :on-preview="handlePictureCardPreview"
              :on-remove="handleRemove"
              :before-upload="beforeUpload">
            <i class="el-icon-plus"></i>
          </el-upload>
          <el-dialog :visible.sync="dialogVisible">
            <img width="100%" :src="dialogImageUrl" alt="">
          </el-dialog>
        </el-form-item>
      </el-form>
      <el-popconfirm
          confirm-button-text='确定退出！'
          cancel-button-text='继续发布！'
          icon="el-icon-info"
          icon-color="red"
          title="您确定要退出吗？"
          @confirm="closeSubmitWorkDetail"
      >
        <el-button type="info" slot="reference">取消</el-button>
      </el-popconfirm>
      <el-button type="success" :loading="false" @click="submitWorks()" :disabled="submitWorksIsDisabled">发布
      </el-button>
    </div>
  </div>
</template>

<script>

import {getToken} from "@/utils/auth";

export default {
  data() {
    return {
      user: localStorage.loginInfo ? JSON.parse(localStorage.loginInfo) : null,
      SubmitWorkDetailShow: false,
      submitWorksIsDisabled: false,
      categoryOptions: [],
      uploadFileList: [],
      formLabelAlign: {
        categoryId: null,
        categoryName: null,
        title: null,
        brief: null,
        coverUrl: null,
        keywords: null,
        detail: null,
        images: [],
      },
      dialogImageUrl: '',
      dialogVisible: false,
      title: '',
      value: '',
      uploadUrl: this.BaseUrl.UPLOAD + "/offcialWebsite/userOffcialWebsiteArticle/upload/articleResource",
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    };
  },
  computed: {},
  methods: {
    reset() {
      this.formLabelAlign = {
        categoryId: null,
        categoryName: null,
        title: null,
        brief: null,
        coverUrl: null,
        keywords: null,
        detail: null,
        images: [],

      };
    },
    closeSubmitWorkDetail() {
      this.SubmitWorkDetailShow = false;
    },
    // 选择文件改变事件
    handleFileChange(file, fileList) {
      this.uploadFileList = fileList
    },

    handleRemove(file, fileList) {
      this.uploadFileList = fileList
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleSuccess(response, file, fileList) {
      if (response.code === 200) {
        // 将当前上传成功的图片 URL 存储到 images 数组中
        this.formLabelAlign.images.push(response.urls[0]); // response.urls 应该是数组，取其中一个 URL

        // 检查是否所有图片都上传完毕
        if (this.formLabelAlign.images.length === fileList.length) {
          // 如果已上传图片数量等于 fileList 的总长度，表示所有图片上传成功
          this.publishArticle(); // 所有图片上传完毕，发布文章
        }
      }
    },

    handleError(err, file, fileList) {
      this.$message.error("图片上传失败，请重试");
      this.submitWorksIsDisabled = false;
    },
    beforeUpload(file) {
      this.formLabelAlign.images = [];
      if (!getToken()) {
        this.$message.error("请先登录后在进行此操作")
        localStorage.removeItem('loginInfo');
        location.reload()
      }
      //file.url = URL.createObjectURL(file.raw);
    },
    submitWorks() {
      if (!getToken()) {
        this.$message.error("请先登录后在进行此操作")
        localStorage.removeItem('loginInfo');
        location.reload()
        return;
      }
    //  console.log(this.uploadFileList)
      this.$refs.upload.submit();  // 触发上传


      /*    this.submitWorksIsDisabled = true
          let url = this.BaseUrl.ARTICLE_MANAGEMENT + '/offcialWebsite/userOffcialWebsiteArticle/add'
          this.formLabelAlign.categoryId = this.value
          if (this.formLabelAlign.images.length>0){
            this.formLabelAlign.coverUrl = this.formLabelAlign.images[0]
          }
          this.TokenAxios.post(url, this.formLabelAlign).then((response) => {
            if (response.data.code === 200) {
              this.submitWorksIsDisabled = false
              //重置表单
              this.reset();
              this.$message.success("作品发布成功！")
              this.closeSubmitWorkDetail();
            } else {
              this.submitWorksIsDisabled = false
              this.$message.error(response.data.message)
              //TODO 删除上传图片
            }
          }).catch((error) => {
          })*/
    },
    // 图片上传成功后发布文章
    publishArticle() {
      this.submitWorksIsDisabled = true;
      this.formLabelAlign.categoryId = this.value;
      if (this.formLabelAlign.images.length > 0) {
        this.formLabelAlign.coverUrl = this.formLabelAlign.images[0];
      }

      let url = this.BaseUrl.ARTICLE_MANAGEMENT + '/offcialWebsite/userOffcialWebsiteArticle/add';
      this.TokenAxios.post(url, this.formLabelAlign).then((response) => {
        this.submitWorksIsDisabled = false;
        if (response.data.code === 200) {
          // 重置表单
          this.reset();
          this.$message.success("作品发布成功！");
          this.closeSubmitWorkDetail();
        }else if(response.data.code === 401||response.data.code === 403){
          console.error("登陆已过期请重新登陆");
          this.logOut()
        } else {
          this.$message.error(response.data.message);
        }
      }).catch((error) => {
        this.submitWorksIsDisabled = false;
        console.error(error);
      });
    },


    getCategories() {
      let url = this.BaseUrl.ARTICLE_MANAGEMENT + '/offcialWebsite/userOffcialWebsiteArticleCategory/list';
      this.SimpleAxios.get(url, this.categoryQueryParams)
          .then((response) => {
            if (response.data.code === 200) {
              this.categoryOptions = response.data.data
            } else {
              this.$message.error(response.data.message)
            }
          }).catch((error) => {
      })
    },
  },
  created() {
    this.getCategories();
  },

}
</script>

<style scoped>
.submit-work-detail {
  position: fixed;
  width: auto;
  min-width: 272px;
  max-height: 100%;
  overflow-y: auto;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  border-radius: 15px;
  padding: 10px 30px 10px 10px;
  text-align: center;
  z-index: 999;
}

.submit-work-detail-heard {

}

.submit-work-detail-close {
  margin-left: 60%;
  border: 0;
}

</style>
